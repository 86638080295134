import * as React from "react";
import { graphql, PageProps } from "gatsby";
import styled from "styled-components";
import JotformEmbed from "react-jotform-embed";

import { Query } from "@graphql-types";
import Hero from "@shared/hero";
import { BlocksContent, HeroCarousel, Breadcrumb } from "@global";
import ContactCTABlock from "@shared/contactCTA";
import { Container, HeroH2 } from "@util/standard";
import { colors, MOBILE_BREAKPOINT } from "@util/constants";
import SEO from "@shared/seo";
import ContactLocationInfos from "@shared/contactLocationInfo";
import Layout from "@shared/layout";
import { BreadcrumbPageContext } from "@util/types";

const ContentContainer = styled(Container)`
  width: 80%;
  flex-direction: column;
  margin: 5% auto;

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 84%;
  }
`;

interface Props extends PageProps {
  data: Query;
  pageContext: BreadcrumbPageContext;
}

const ContactUsPage = ({ pageContext, data }: Props) => {
  const sanityContactPage = data.allSanityContactPage.nodes[0];
  if (sanityContactPage == null) {
    return null;
  }
  return (
    <>
      <SEO seoData={sanityContactPage.seo} />
      <Layout>
        {sanityContactPage &&
        sanityContactPage.additionalHeros &&
        sanityContactPage.additionalHeros.length > 0 ? (
          <HeroCarousel
            hero={sanityContactPage?.hero}
            additionalHeros={sanityContactPage.additionalHeros}
          />
        ) : (
          <Hero height="650px" sanityHero={sanityContactPage?.hero} />
        )}
        <Breadcrumb pageContext={pageContext} />
        {sanityContactPage?.contactCTA &&
          sanityContactPage?.contactCtaHeading && (
            <ContentContainer>
              <HeroH2 color={colors.merlin}>
                {sanityContactPage?.contactCtaHeading}
              </HeroH2>

              <ContactCTABlock contactctaInfo={sanityContactPage?.contactCTA} />
            </ContentContainer>
          )}

        <ContentContainer>
          <BlocksContent blocks={sanityContactPage.contactInfo?._rawBlocks} />
          {sanityContactPage?.contactLocationInfo && (
            <ContactLocationInfos
              contactLocations={sanityContactPage?.contactLocationInfo}
            />
          )}
          <Container margin="50px 0">
            <JotformEmbed src="https://form.jotform.com/230367933521051" />
          </Container>
        </ContentContainer>
      </Layout>
    </>
  );
};

export default ContactUsPage;

export const query = graphql`
  query allContactQuery($iban: String) {
    allSanityContactPage(filter: { region: { iban: { eq: $iban } } }) {
      nodes {
        hero {
          ...sanityHero
        }
        seo {
          ...sanitySeo
        }
        contactCtaHeading
        contactCTA {
          _key
          title
          ctas {
            _key
            url
            _type
            internallink
            linktext
            newwindow
          }
        }
        contactInfo {
          _rawBlocks
          _key
        }
        contactLocationInfo {
          _key
          _rawBlocks
          _type
          blocks {
            _key
            _type
            children {
              text
              marks
              _key
            }
            list
            style
          }
        }
      }
    }
  }
`;
