import React from "react";
import { Maybe, SanityContactCta } from "@graphql-types";
import { Link } from "@global";
import {
  IPAD_PRO_BREAKPOINT,
  IPAD_BREAKPOINT,
  colorsRGB,
  colors,
  fontWeights,
  fontSizes,
} from "@util/constants";
import styled from "styled-components";

interface Props {
  contactctaInfo: Maybe<SanityContactCta>[];
}

const CtaContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 1rem;
  margin-top: 15px;
  @media only screen and (max-width: ${IPAD_BREAKPOINT}px) {
    margin-top: 10px;
    margin-bottom: 40px;
    grid-gap: 10px;
  }
`;
const CtaRow = styled.div`
  background-color: ${colorsRGB.merlin(0.04)};
  padding: 60px;
  text-align: center;
  &.half--column {
    grid-column: span 3;
  }
  &.one-third--column {
    grid-column: span 2;
  }
  span {
    font-weight: ${fontWeights.bold};
  }
  .cta--btn {
    &:hover {
      opacity: 1;
    }
    p {
      font-size: ${fontSizes.p.alternate}px;
      text-transform: uppercase;
      letter-spacing: 0.08em;
      border: 1.5px solid ${colors.merlin};
      border-radius: 36px;
      font-weight: ${fontWeights.bold};
      padding: 14px 40px;
      width: fit-content;
      margin: 15px auto 0;
      &:hover {
        background-color: ${colors.merlin};
        color: ${colors.white};
        text-decoration: none;
      }
    }
  }
  @media only screen and (max-width: ${IPAD_PRO_BREAKPOINT}px) {
    &.half--column,
    &.one-third--column {
      grid-column: span 6;
    }
  }
  @media only screen and (max-width: ${IPAD_BREAKPOINT}px) {
    padding: 40px;
    .cta--btn p {
      font-size: ${fontSizes.button.mobile}px;
      padding: 11px 25px;
    }
  }
`;

const ContactCTABlock = (props: Props) => {
  const { contactctaInfo } = props;
  return (
    <CtaContainer>
      {contactctaInfo.map((contactCta, index) => {
        const gridClass =
          index == 0 || index == 1 ? "half--column" : "one-third--column";
        return (
          <CtaRow className={gridClass} key={contactCta?._key}>
            <span>{contactCta?.title}</span>
            <Link
              className="cta--btn"
              linktext={contactCta?.ctas?.linktext}
              url={contactCta?.ctas?.url}
              internallink={contactCta?.ctas?.internallink}
              newwindow={contactCta?.ctas?.newwindow}
            />
          </CtaRow>
        );
      })}
    </CtaContainer>
  );
};

export default ContactCTABlock;
