import React from "react";
import { Maybe, SanityBlockContent } from "@graphql-types";
import { BlocksContent } from "@global";
import styled from "styled-components";
import { IPAD_BREAKPOINT } from "@util/constants";
interface Props {
  contactLocations: Maybe<Maybe<SanityBlockContent>[]>;
}

const ContactLocationContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  @media only screen and (max-width: ${IPAD_BREAKPOINT}px) {
    grid-template-columns: 1fr;
  }
`;
const InnerContainer = styled.div`
  @media only screen and (min-width: ${IPAD_BREAKPOINT + 1}px) {
    &:first-child {
      border-right: 1px solid black;
      padding-right: 15%;
    }
    &:last-child {
      padding-left: 15%;
    }
  }
  @media only screen and (max-width: ${IPAD_BREAKPOINT}px) {
    padding-bottom: 8%;
    &:first-child {
      border-bottom: 1px solid black;
      margin-bottom: 12%;
    }
  }
`;
const ContactLocationInfos = (props: Props) => {
  const { contactLocations } = props;
  return (
    <ContactLocationContainer>
      {contactLocations?.map(locations => {
        return (
          <InnerContainer key={locations?._key}>
            <BlocksContent blocks={locations?._rawBlocks} />
          </InnerContainer>
        );
      })}
    </ContactLocationContainer>
  );
};

export default ContactLocationInfos;
